import React from "react"

//Components
import Layout from "components/layout"
import SEO from "components/seo"

//Assets
import NotFoundPNG from "@public/img/sample/not-found.png"
import { Card, Image, Button, Link, ButtonGroup } from "components/anti"
import { useRouter } from "next/router"

const NotFoundPage = () => {
  const { locale } = useRouter()
  return (
    <>
      <SEO title="Not Found" />
      <Layout>
        <div className="sc-404 py-main h-100 container d-flex justify-content-center align-items-center">
          <div className="w-md-50 w-100 py-main">
            <Image className="img-fluid" src={NotFoundPNG} />
            <h3 className="title mt-4">
              {locale === "id" ? "HALAMAN TIDAK DITEMUKAN" : "PAGE NOT FOUND"}
            </h3>
            <h6 className="subtitle">
              {locale === "id"
                ? "Kami tidak dapat menemukan apa yang sedang kamu cari"
                : "We couldn’t find what you were looking for."}
            </h6>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="white"
                link="/"
                className="w-100 w-md-50 w-lg-auto"
              >
                {locale === "id" ? "Kembali ke Beranda" : "Back to Home"}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
